import React from 'react'
import { Link, graphql } from 'gatsby'
import marked from 'marked'
import Layout from '../components/Layout'
import SEO from '../components/seo'
import moment from 'moment'

function slugify(text) {
  return text
    .toString()
    .toLowerCase()
    .replace(/\s+/g, '-') // Replace spaces with - // .replace(/[^\w\-]+/g, "") // Remove all non-word chars // .replace(/\-\-+/g, "-") // Replace multiple - with single -
    .replace(/[^\w-]+/g, '') // Remove all non-word chars
    .replace(/--+/g, '-') // Replace multiple - with single -
    .replace(/^-+/, '') // Trim - from start of text
    .replace(/-+$/, '') // Trim - from end of text
}

class ServiceAreasTemplate extends React.Component {
  render() {
    const service = this.props.data.service
    const siteTitle = this.props.data.service.metaTitle
    const { previous, next } = this.props.pageContext
    // console.log(service)
    const servcategory = service.serviceCategory[0].county
    // console.log(servcategory)
    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO
          title={`${service.title} | J&J Automatic Gate Service & Design`}
          description={
            (service.metaDescription && service.metaDescription) ||
            'not specified'
          }
        />

        <div
          id="blog-hero-section"
          className="blog-hero-section text-center"
          style={{
            background:
              (service.mainImage && `url(${service.mainImage.asset.url})`) ||
              '',
            backgroundSize: `cover`,
            backgroundPosition: `center`,
            backgroundRepeat: `no-repeat`,
          }}
        >
          <h1>
            {service.title}
            <span className="published-date d-block">
              {moment(service.publishedAt).format('MMMM Do, YYYY')}
            </span>
          </h1>
        </div>
        <div className="container blogpost-wrap mt-5 text-center">
          <div dangerouslySetInnerHTML={{ __html: marked(service.body) }} />

          <ul
            style={{
              display: `flex`,
              flexWrap: `wrap`,
              justifyContent: `space-between`,
              listStyle: `none`,
              padding: 0,
            }}
          >
            <li>
              {previous && (
                <Link to={previous.slug.current} rel="prev">
                  ← {previous.title}
                </Link>
              )}
            </li>
            <li>
              {next && (
                <Link to={next.slug.current} rel="next">
                  {next.title} →
                </Link>
              )}
            </li>
          </ul>
          <Link to={`/service-area/texas/${slugify(servcategory)}`}>
            Return to {servcategory}
          </Link>
          <br />
          <br />
        </div>
      </Layout>
    )
  }
}

export default ServiceAreasTemplate

export const query = graphql`
  query ServiceAreasTemplateQuery($id: String!) {
    service: sanityServiceArea(id: { eq: $id }) {
      id
      title
      body
      author {
        id
        name
      }
      serviceCategory {
        id
        county
      }
      mainImage {
        asset {
          id
          url
          originalFilename
          fluid {
            src
          }
        }
      }
      metaTitle
      metaKeywords
      metaDescription
    }
  }
`
